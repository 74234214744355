<template>
  <v-skeleton-loader
    :loading="loading"
    height="100%"
    :types="{ skeleton: 'list-item-avatar, divider@3,table-heading,table-tbody' }"
    type="skeleton"
    transition="scale-transition"
  >
    <EditRole />
  </v-skeleton-loader>
</template>


<script>
  import { mapState, mapActions,mapGetters } from 'vuex';
  import EditRole from '../../components/roles/EditRole';

  export default {
    name: 'RoleEditContainer',
    components: {
      EditRole,
    },
    data() {
      return {};
    },
    computed: {
      ...mapState('parametrages/editRole', {
        loading: state => state.loading,
      }),
    ...mapGetters('session', [
        'getPerimetreUser',
      ]),
    },
    created() {
      this.loadRoleByName(this.$route.params.roleName);
    },
    methods: {
      ...mapActions('parametrages/editRole', [
        'loadRoleByName',
      ]),
    },
  };
</script>
