<template>
  <div>
    <v-card class="pa-5">
      <v-row class="pb-4">
        <v-col cols="auto">
          <router-link to="/roles">
            <v-btn
              fab
              small
            >
              <v-icon>mdi-keyboard-backspace</v-icon>
            </v-btn>
          </router-link>
        </v-col>
        <v-col>
          <v-card-title
            primary-title
            class="title"
            :v-if="!loading"
          >
            Editer les droits du rôle {{ role.nom }}
          </v-card-title>
        </v-col>
      </v-row>
      <v-tabs
        grow
        class="subheading"
        centered
        icons-and-text
      >
        <v-tab
          v-for="(tabContent, name , index) in arbo"
          :key="index"
          :href="`#tab-${index}`"
        >
          {{ tabContent.label }}
          <v-icon>
            {{ tabContent.icon }}
          </v-icon>
        </v-tab>

        <v-tab-item
          v-for="(tabContent, label, index) in arbo"
          :key="index"
          :value="'tab-' + index"
        >
          <v-card
            flat
            tile
          >
            <v-expansion-panels
              v-model="panel"
              accordion
            >
              <v-expansion-panel
                v-for="(array, name ,i) in tabContent"
                :key="i"
              >
                <div
                  v-if="!(name === 'icon' || name === 'label')"
                >
                  <v-expansion-panel-header>
                    {{ array.label }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <EditRoleTable
                      :table-arbo-droits="array"
                      :role="role"
                    />
                  </v-expansion-panel-content>
                </div>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import { ARBO_DROITS } from './../../auth/droits.js';
  import EditRoleTable from './editRole/EditRoleTable';

  export default {
    name: 'EditRole',
    components:{
      EditRoleTable,
    },
    data: () => ({
      arbo: ARBO_DROITS,
      panel: 2,
      headers: [
        {
          text: 'Action',
          value: 'action',
        },
        {
          text: 'Nom',
          value: 'nom',
        },
      ],
    }),
    computed: {
      ...mapState('parametrages/editRole', {
        role: state => state.roleByName,
        loading: state => state.loading,
      }),
    },
  };
</script>
<style lang="scss">
  .table-droits{
    .v-messages.theme--light {
      min-height: 0;
    }
     p{
      margin-bottom: 10px;
      margin-top: 6px;
    }
    .v-input--selection-controls {
      margin-top: 0;
    }
  }
</style>
