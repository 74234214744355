<template>
  <div>
    <v-data-table
      light
      :headers="headers"
      :loading="loading"
      class="elevation-1 table-droits"
      loading-text="Chargement en cours"
      no-data-text="Aucun rôle trouvé"
      :items="dataTable"
      :sort-by="['label']"
      :items-per-page="20"
    >
      <template v-slot:item.action="{ item }">
        <v-switch
          v-model="item.actif"
          :disabled="item.droit === loadingUpdate"
          @change="sendUpdatedRight(item.droit,item.actif)"
        />
      </template>
    </v-data-table>
  </div>
</template>
<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import _ from 'lodash';

  export default {
    name: 'Table',
    props: {
      tableArboDroits: {
        type: Object,
        required: true,
      },
      role: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      dataTable: [],
      headers: [
        {
          text: 'Action',
          value: 'action',
        },
        {
          text: 'Nom',
          value: 'label',
        },
      ],
    }),
    computed: {
      ...mapState('parametrages/editRole', {
        loading: state => state.loading,
        loadingUpdate: state => state.loadingUpdate,
      }),
      ...mapGetters('parametrages/editRole', {
        listDroitsModifiable: 'getRoleRights' }
      ),
      ...mapGetters('session', {
        userDroits: 'getDroits' }
      ),
    },
    watch: {
      listDroitsModifiable() {
        this.filterRights();
      },
    },
    created() {
      this.filterRights();
    },
    methods: {
      ...mapActions('parametrages/editRole', [
        'activateRight',
        'deactivateRight',
      ]),
      filterRights(){
        let newArboObject = _.cloneDeep(this.tableArboDroits);
        delete newArboObject.label;
        let listToCheck = this.listDroitsModifiable;
        let valuesArbo = Object.values(newArboObject);
        for (let obj in valuesArbo) {
          for (let actif in listToCheck){
            if (valuesArbo[obj].droit === listToCheck[actif].nom){
              valuesArbo[obj].actif = listToCheck[actif].actif;
            }
          }
        }
        this.dataTable = valuesArbo.filter(item => item.actif !== undefined);
      },
      sendUpdatedRight(droit,actif) {
        if(actif){
          this.activateRight({ 'droit' : droit,'role': this.role });
        }else if (!actif){
          this.deactivateRight({ 'droit' : droit,'role': this.role });
        }
      },
    },
  };
</script>
